import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useEffect
} from "react-router-dom";
import Main from "./layout/main/Main";
import Home from "./pages/home/Home";

function App() {
  
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Main />
        </Route>
        <Main />
      </Switch>
    </Router>
  );
}

export default App;
