import React from "react";
import "./aboutus.scss";
import { Card, Col, Row } from "antd";
import Divider from "../../images/aboutus-divider.png";
import { arrAboutUs } from "./ArrAbout";
import { useTranslation } from "react-i18next";
function About() {
  const { t } = useTranslation();

  return (
    <div className="about-container">
      <Row justify="center">
        <Col xl={16} sm={20} xs={23} className="about-us-title">
          <Row justify="center">
            <Col>
              <div style={{color: "black"}}>{t("AboutUs")}</div>
            </Col>
          </Row>

          <Row justify="center">
            <Col>
              <p>{t("AboutUsTitle")}</p>
            </Col>
          </Row>

          <Row justify="center">
            <Col xl={12} xs={23} className="desc">
              <p>{t("AboutUsDesc")}</p>
            </Col>
          </Row>

          <Row justify="center">
            <Col className="image-divider">
              <img src={Divider} alt="divider" />
            </Col>
          </Row>

          <Row justify="center">
            <Col>
              <div>{t("theFastestLoanExperience")}</div>
            </Col>
          </Row>

          <Row justify="center">
            <Col>
              <p>{t("fastestDesc")}</p>
            </Col>
          </Row>

          <Row
            justify="center"
            gutter={[50, 50]}
            className="fastest-loan-container"
          >
            {arrAboutUs?.map((items) => {
              return (
                <Col xl={6} sm={12} xs={23} className="">
                  <div className="faster-loan-wrapper">
                    <Card>
                      <div className="min-image">
                        <img src={items.image} alt="min" />
                      </div>

                      <Row justify="center">
                        <Col>
                          <div className="title">{t(items.title)}</div>
                        </Col>
                      </Row>

                      <Row justify="center">
                        <Col>
                          <div className="desc">{t(items.desc)}</div>
                        </Col>
                      </Row>

                      <Row justify="center">
                        <Col>
                          <div className="desc2">{t(items.desc2)}</div>
                        </Col>
                      </Row>
                    </Card>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default About;
