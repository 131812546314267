import { Button, Card, Col, Form, Input, Row } from "antd";
import React from "react";
import "./contact.scss";
import { WhatsAppOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

function Contact() {
  const { t } = useTranslation();
  return (
    <div className="contact-container">
      <div className="contact-title-container">
        <Row justify="center">
          <Col>
            <p className="title"> {t("letsConnect")}</p>
            <p className="desc">{t("letsContactDesc")}</p>
          </Col>
        </Row>
      </div>

      <div className="contact-desc-container">
        <Row justify="center">
          <Col xs={24}>
            <div className="contact-desc-tittle">{t("contactUs")}</div>
          </Col>
        </Row>

        <Row justify="center">
          <Col xl={14} xs={23}>
            <Card>
              <Row>
                <Col xs={24}>
                  <div className="card-title">{t("sendUsMessage")} </div>
                </Col>
                <Col xs={24}>
                  <div className="card-desc">{t("sendUsMessageDesc")}</div>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col>
                  <Button
                    icon={<WhatsAppOutlined />}
                    onClick={() => window.open("https://gomesej.org/b11")}
                  >
                    WhatsApp 1
                  </Button>
                </Col>

                <Col>
                  <Button
                    icon={<WhatsAppOutlined />}
                    onClick={() => window.open("https://gomesej.org/b22")}
                  >
                    WhatsApp 2
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Contact;
